
























import { Component, Prop, Vue } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { Inject } from 'inversify-props';
import { Input } from 'element-ui';
import RoomTypeModel from '@/modules/room-types/models/room-type.model';
// @ts-ignore
import ModalWrapper, { DialogOptions } from '@/modules/common/components/modal-wrapper.vue';
import { ValidationError } from 'class-validator';
import ValidationErrorComponent from '@/modules/common/components/ui-kit/validation-error.vue';
import RoomsTypeManagerService, { RoomsTypeManagerServiceS } from '../../rooms-type-manager/rooms-type-manager.service';
import RoomTypesService, { RoomTypesServiceS } from '../room-types.service';

@Component({
    components: {
        ModalWrapper,
        ValidationErrorComponent,
        draggable,
        'el-input': Input,
    },
})
export default class DeleteRoomPopup extends Vue {
    @Inject(RoomsTypeManagerServiceS) protected hotelsRoomTypeService!: RoomsTypeManagerService;
    @Inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;

    @Prop({ required: true })
    private roomTypeId!: number;

    validationErrors: ValidationError[] | null = null;
    roomTypeName: string | null = this.roomTypeId ? this.roomTypesService.roomName(this.roomTypeId) : null;

    async deleteRoom(closeModal: Function) {
        if (!this.roomTypeName) return;

        const errors = await this.roomTypesService.deleteRoom(this.roomTypeId);

        if (errors.length) {
            this.validationErrors = [...errors];
        } else {
            this.hotelsRoomTypeService.resetLocalChanges();
            closeModal();
        }
    }
}
