




import { Component, Vue, Prop } from 'vue-property-decorator';
import DeleteRoomPopup from '../components/delete-room.popup.vue';

@Component({
    components: { DeleteRoomPopup },
})

export default class DeleteRoomModalPage extends Vue {
    @Prop({ required: false })
    private roomTypeId?: number;
}
